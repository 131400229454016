import company from '@/modules/strubs/company'
import badword from '@/modules/strubs/badword'

const category = {
  id: 0,
  name: '',
  active: false,
  badwords_count_total: 0,
  badwords_count_active: 0,
  badwords_count_inactive: 0,
  created_at: '',
  updated_at: '',
  company: company,
  badwords: [badword]
}

export default category
