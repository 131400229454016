import company from '@/modules/strubs/company'
import badwordCategory from '@/modules/strubs/badwordCategory'

export default {
  id: 0,
  value: '',
  times: 0,
  active: false,
  created_at: '',
  updated_at: '',
  company: company,
  category: badwordCategory
}
