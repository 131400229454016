<template>
  <div>
    <portal to="page-top-title">Badword #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'badwords-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/badwords/${id}`" data-prop="badword" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Value">{{ badword.value }}</column-info>
          <column-info title="Category">
            <router-link :to="{ name: 'badwords-categories-single', params: { id: badword.category.id } }">{{ badword.category.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ badword.created_at }}</column-info>
          <column-info title="Updated At">{{ badword.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="badword.active"/>
          </column-info>
          <column-info title="Times Used">
            <badge title="Total">{{ badword.times }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import badword from '@/modules/strubs/badword'

export default {
  name: 'BadwordsSingle',
  metaInfo () {
    return { title: 'Badword #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    Badge,
    BadgeActive
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      badword: badword
    }
  }
}
</script>
